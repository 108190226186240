<template>
  <div class="w-forgot">
    <van-nav-bar title="找回密码" left-arrow @click-left="onClickLeft" />

    <div class="main">
      <div class="main-content">
        <div class="main-content-item">
          <span class="tit">手机号码:</span>

          <div class="inputbox">
            <input
              type="text"
              v-model="phoneNumber"
              placeholder="请输入手机号码"
            />
          </div>

          <div class="notice">
            <p v-show="phoneError" class="warn">{{ phoneError }}</p>
          </div>
        </div>

        <div class="main-content-item">
          <span class="tit">新密码:</span>

          <div class="inputbox">
            <input
              type="password"
              v-model="password"
              placeholder="请输入新密码"
            />
          </div>

          <div class="notice">
            <p v-show="!passError">密码格式由8-20位数字、字母组成</p>

            <p class="warn" v-show="passError">{{ passError }}</p>
          </div>
        </div>

        <div class="main-content-item">
          <span class="tit">短信验证码:</span>

          <div class="inputbox">
            <input type="number" v-model="verif" @input="setLength" />
          </div>

          <div class="verif">
            <span class="get-verif" v-if="!isSend" @click="showVerifNotice"
              >获取验证码</span
            >

            <span class="get-verif verif-time" v-if="isSend"
              >重新获取{{ verifOutTime }}s</span
            >
          </div>

          <div class="notice">
            <p v-show="verifError" class="warn">{{ verifError }}</p>
          </div>
        </div>
      </div>

      <p class="err">{{ modifyError }}</p>

      <div class="send" @click="submitModify">提交</div>
    </div>

    <van-popup v-model="sendVerif">
      <div class="over">
        <div class="content">
          <p>
            我们将发送验证码短信到<br />+86&nbsp;
            <span class="phoneNumber">{{ phoneNumber }}</span>
          </p>
        </div>

        <div class="btn-group">
          <span class="cancel" @click="cancel">取消</span>
          <span class="sure" @click="sure">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getcode, forgotPwd } from "@api/user.js";
// import axios from "axios";
import qs from "qs";
export default {
  data() {
    return {
      //手机号码
      phoneNumber: "",
      //新密码
      password: "",
      //发送验证码
      sendVerif: false,
      //是否已经发送验证码
      isSend: false,
      //验证码超时时间
      verifOutTime: 60,
      //验证码
      verif: "",
      //验证码ID
      codeId: "",
      //手机校验
      phoneError: "",
      //密码校验
      passError: "",
      //验证码校验
      verifError: "",
      //修改结果提示
      modifyError: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //点击获取验证码,显示弹层
    showVerifNotice() {
      if (this.phoneNumber == "") {
        this.phoneError = "请输入手机号码";
        return;
      }
      this.sendVerif = true;
    },
    //取消发送验证码
    cancel() {
      this.sendVerif = false;
    },
    //限制验证码长度
    setLength() {
      if (this.verif.length > 6) {
        this.verif = this.verif.slice(0, 6);
      }
    },
    //验证码倒计时
    setTime() {
      let timer = setInterval(() => {
        if (this.verifOutTime > 0) {
          this.verifOutTime--;
        } else {
          clearInterval(timer);
          this.codeId = "";
          this.verifOutTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    //确认发送验证码
    sure() {
      let reg = /^1[3|4|5|7|8][0-9]\d{4,8}$/;
      if (!reg.test(this.phoneNumber)) {
        this.phoneError = "请填写正确的手机号格式!";
        this.sendVerif = false;
        return;
      }
      const params = {
        mobile: this.phoneNumber,
        type: 2,
      };
      getcode(qs.stringify(params))
        .then((res) => {
          this.codeId = res.data.codeId;
        })
        .catch((err) => {
          console.log(err);
        });
      this.sendVerif = false;
      this.isSend = true;
      this.setTime();
    },
    submitModify() {
      if (this.phoneNumber != "" && this.password != "" && this.verif != "") {
        if (!this.codeId) {
          this.modifyError = "请填写正确的验证码";
          return;
        }
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z\d]{8,20}$/;
        if (!reg.test(this.password)) {
          this.passError = "密码格式由8-20位数字、字母组成";
          return;
        }
        const params = {
          code: this.verif,
          codeId: this.codeId,
          mobile: this.phoneNumber,
          newPassword: this.password,
        };
        forgotPwd(params)
          .then((res) => {
            if (res.code == 0) {
              this.$toast("修改成功,请登陆");
              setTimeout(() => {
                this.$router.replace("/login");
              }, 500);
            } else {
              this.modifyError = res.msg;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (this.password == "") {
          this.passError = "请输入新密码";
        }
        if (this.verif == "") {
          this.verifError = "请输入验证码";
        }
      }
    },
  },
  watch: {
    phoneNumber() {
      this.phoneError = "";
    },
    password() {
      this.passError = "";
    },
    verif() {
      this.verifError = "";
    },
    modifyError() {
      let timer = setTimeout(() => {
        this.modifyError = "";
        clearTimeout(timer);
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #333;
}
::v-deep .van-nav-bar__title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-popup {
  border-radius: 10px;
}
.over {
  width: 285px;
  height: 169px;
  .content {
    height: 115px;
    padding: 50px 60px 0;
    border-bottom: 1px solid #eee;
    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 45px;
    span {
      font-size: 15px;
      font-weight: 500;
      color: #999;
    }
    .sure {
      color: #4588ff;
    }
  }
}
.w-forgot {
  font-family: PingFang SC;
  .main {
    border-top: 11px solid #f4f4f4;
    padding: 0 30px;
    &-content {
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        height: 63px;
        border-bottom: 1px solid #eee;
        .tit {
          position: relative;
          display: inline-block;
          width: 85px;
          flex-shrink: 0;
          padding-left: 6px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          &::before {
            content: "*";
            position: absolute;
            display: block;
            left: 0;
            color: red;
            font-size: 12px;
          }
        }
        .inputbox {
          width: 200px;
          height: 19px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          input {
            width: 100%;
            padding: 0 25px 0 16px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
        }
        .notice {
          position: absolute;
          left: 100px;
          bottom: 0;
          font-size: 12px;
          color: #999;
          .warn {
            color: red;
          }
        }
        .verif {
          display: flex;
          align-items: center;
          margin-left: 15px;
          text-align: center;
          .get-verif,
          .get-verif.verif-time {
            font-size: 11px;
            font-weight: 500;
            color: #4588ff;
          }
          .get-verif.verif-time {
            color: #999;
          }
        }
      }
      &-item:last-child {
        .inputbox {
          width: 150px;
          input {
            border-right: 1px solid #eee;
          }
        }
      }
    }
    .err {
      text-align: center;
      font-size: 12px;
      color: red;
    }
    .send {
      width: 302.5px;
      height: 57px;
      margin: 130px auto 0;
      padding-top: 15px;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      color: #fefefe;
      background: url("~@image/modify-nick-btn.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
